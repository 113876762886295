<template>
  <div>
    <div class="container mt-6">
      <div id="timelind">
        <nav class="timeline-nav mb-5">
          <div class="inner">
            <ul>
              <li :class="checkstatus1" style="left: 0px" @click="firsthistoy">
                <a href="#history-1998" onclick="return false">1998 ~ 2000</a>
              </li>
              <li :class="checkstatus2" :style="secondmovestatus" @click="secondhistory">
                <a href="#history-2001" onclick="return false">2001 ~ 2010</a>
              </li>
              <li :class="checkstatus3" :style="thirdmovestatus" @click="thirdhistory">
                <a href="#history-2011" onclick="return false">2011 ~ 2020</a>
              </li>
              <li :class="checkstatus4" :style="fourthmovestatus" @click="fourthhistory">
                <a href="#history-2021" onclick="return false">2021 ~ 2030</a>
              </li>
            </ul>
          </div>
        </nav>

        <setcion id="history-1998" :style="viewhistory1">
          <div class="ml-1">
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2000</th>
                <td>10 JONNIE WALKER ASIAN NATIONS CUP 개최</td>
              </tr>
              <tr>
                <td>12 제2회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">1999</th>
                <td>01 회원제 골프장 등록</td>
              </tr>
              <tr>
                <td>12 제1회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th>1998</th>
                <td>09 일반제 골프장 등록</td>
              </tr>
            </table>

            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2000</th>
              </tr>
              <tr>
                <td>10</td>
                <td>JONNIE WALKER ASIAN NATIONS CUP 개최</td>
              </tr>
              <tr>
                <td>12</td>
                <td>제2회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">1999</th>
              </tr>
              <tr>
                <td>01</td>
                <td>회원제 골프장 등록</td>
              </tr>
              <tr>
                <td>12</td>
                <td>제1회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">1998</th>
              </tr>
              <tr>
                <td>09</td>
                <td>일반제 골프장 등록</td>
              </tr>
            </table>
          </div>
        </setcion>

        <setcion id="history-2001" :style="viewhistory2">
          <div class="ml-1">
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="4">2010</th>
                <td>03 핀크스 Museums Awards the 23rd Murano Togo Awards(일본) 수상</td>
              </tr>
              <tr>
                <td>04 2010 유러피언 투어 발렌타인 챔피언십 개최</td>
              </tr>
              <tr>
                <td>09 SK핀크스(주) 통합법인 발족</td>
              </tr>
              <tr>
                <td>11 Asian Golf Monthly Award 2010 Best Course in KOREA선정</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2009</th>
                <td>04 2009 유러피안투어 발렌타인 챔피언십 개최</td>
              </tr>
              <tr>
                <td>05 골프다이제스트 2009-2010 한국 베스트 코스 6위 선정</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="3">2008</th>
                <td>03 2008 유러피안투어 발렌타인 챔피언십 개최(국내 최초)</td>
              </tr>
              <tr>
                <td>12 Asian Golf Monthly Award 2008 Best Course in KOREA선정</td>
              </tr>
              <tr>
                <td>12 2008 the PINX CUP 제 9회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2007</th>
                <td>05 골프다이제스트 2007-2008 한국 베스트 코스 2위 선정</td>
              </tr>
              <tr>
                <td>05 미국 골프다이제스트 2007-2008 세계 100대 골프코스 99위 선정(미국 제외)</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="3">2006</th>
                <td>04 포도호텔 제 2회 아시아 주거문화 및 주거 경관상 수상</td>
              </tr>
              <tr>
                <td>06 핀크스 비오토피아 미술관 제 17회 김수근문화상 수상</td>
              </tr>
              <tr>
                <td>10 비오토피아 두손 지중 미술관 개관</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="5">2005</th>
                <td>05 골프다이제스트 2005-2006 한국 베스트 코스 1위 선정</td>
              </tr>
              <tr>
                <td>05 미국 골프다이제스트 2005-2006 국내최초 세계 100대 골프코스 72위 선정(미국 제외)</td>
              </tr>
              <tr>
                <td>05 비오토피아 ‘水’, ‘風’, ‘石’ 미술관 개관</td>
              </tr>
              <tr>
                <td>11 영국 골프월드 2005년 세계 100대 골프코스 79위 선정</td>
              </tr>
              <tr>
                <td>12 2005 the PINX CUP 제 6회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th>2004</th>
                <td>07 포도호텔 프랑스 예술문화 훈장 슈발리에 수상</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="3">2003</th>
                <td>01 포도호텔 제 6회 명가명인상 수상</td>
              </tr>
              <tr>
                <td>11 골프다이제스트 2003-2004 한국 베스트 코스 1위 선정</td>
              </tr>
              <tr>
                <td>12 2003 우리금융 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th>2001</th>
                <td>12 포도호텔 개관</td>
              </tr>
            </table>

            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2010</th>
              </tr>
              <tr>
                <td>03</td>
                <td>핀크스 Museums Awards the 23rd Murano Togo Awards(일본) 수상</td>
              </tr>
              <tr>
                <td>04</td>
                <td>2010 유러피언 투어 발렌타인 챔피언십 개최</td>
              </tr>
              <tr>
                <td>09</td>
                <td>SK핀크스(주) 통합법인 발족</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Asian Golf Monthly Award 2010 Best Course in KOREA선정</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2009</th>
              </tr>
              <tr>
                <td>04</td>
                <td>2009 유러피안투어 발렌타인 챔피언십 개최</td>
              </tr>
              <tr>
                <td>05</td>
                <td>골프다이제스트 2009-2010 한국 베스트 코스 6위 선정</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2008</th>
              </tr>
              <tr>
                <td>03</td>
                <td>2008 유러피안투어 발렌타인 챔피언십 개최(국내 최초)</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Asian Golf Monthly Award 2008 Best Course in KOREA선정</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2008 the PINX CUP 제 9회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2007</th>
              </tr>
              <tr>
                <td>05</td>
                <td>골프다이제스트 2007-2008 한국 베스트 코스 2위 선정</td>
              </tr>
              <tr>
                <td>05</td>
                <td>미국 골프다이제스트 2007-2008 세계 100대 골프코스 99위 선정(미국 제외)</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2006</th>
              </tr>
              <tr>
                <td>04</td>
                <td>포도호텔 제 2회 아시아 주거문화 및 주거 경관상 수상</td>
              </tr>
              <tr>
                <td>06</td>
                <td>핀크스 비오토피아 미술관 제 17회 김수근문화상 수상</td>
              </tr>
              <tr>
                <td>10</td>
                <td>비오토피아 두손 지중 미술관 개관</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2005</th>
              </tr>
              <tr>
                <td>05</td>
                <td>골프다이제스트 2005-2006 한국 베스트 코스 1위 선정</td>
              </tr>
              <tr>
                <td>05</td>
                <td>미국 골프다이제스트 2005-2006 국내최초 세계 100대 골프코스 72위 선정(미국 제외)</td>
              </tr>
              <tr>
                <td>05</td>
                <td>비오토피아 ‘水’, ‘風’, ‘石’ 미술관 개관</td>
              </tr>
              <tr>
                <td>11</td>
                <td>영국 골프월드 2005년 세계 100대 골프코스 79위 선정</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2005 the PINX CUP 제 6회 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2004</th>
              </tr>
              <tr>
                <td>07</td>
                <td>포도호텔 프랑스 예술문화 훈장 슈발리에 수상</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2003</th>
              </tr>
              <tr>
                <td>01</td>
                <td>포도호텔 제 6회 명가명인상 수상</td>
              </tr>
              <tr>
                <td>11</td>
                <td>골프다이제스트 2003-2004 한국 베스트 코스 1위 선정</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2003 우리금융 핀크스컵 한일 여자 프로골프 대항전 주최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2001</th>
              </tr>
              <tr>
                <td>12</td>
                <td>포도호텔 개관</td>
              </tr>
            </table>
          </div>
        </setcion>

        <setcion id="history-2011" :style="viewhistory3">
          <div class="ml-1">
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="4">2018</th>
                <td>03 포도호텔 갤러리 오픈</td>
              </tr>
              <tr>
                <td>07 디아넥스 베이비룸, 패밀리룸 신설</td>
              </tr>
              <tr>
                <td>10 SK네트웍스-서울경제 레이디스 클래식 개최</td>
              </tr>
              <tr>
                <td>11 World Golf Award 2018 한국 베스트 골프코스 수상</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="3">2017</th>
                <td>04 디아넥스 패밀리 스위트, 스위트 객실 신설</td>
              </tr>
              <tr>
                <td>04 디아넥스 북카페 및 스포츠 홀 오픈</td>
              </tr>
              <tr>
                <td>10 SK네트웍스-서울경제 레이디스 클래식 개최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th>2016</th>
                <td>11 World Golf Award 2016 한국 베스트 골프코스 수상</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2015</th>
                <td>01 디아넥스 준공</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2013</th>
                <td>05 골프다이제스트 2013-2014 Korea Best course Silver부문 수상</td>
              </tr>
              <tr>
                <td>06 SK telecom 2013 개최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th>2012</th>
                <td>05 SK telecom 2012 개최</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="2">2011</th>
                <td>05 SK telecom 2011 개최</td>
              </tr>
              <tr>
                <td>05 골프다이제스트 2011-2012 한국 베스트 코스 골프 부문 수상</td>
              </tr>
            </table>

            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2018</th>
              </tr>
              <tr>
                <td>03</td>
                <td>포도호텔 갤러리 오픈</td>
              </tr>
              <tr>
                <td>07</td>
                <td>디아넥스 베이비룸, 패밀리룸 신설</td>
              </tr>
              <tr>
                <td>10</td>
                <td>SK네트웍스-서울경제 레이디스 클래식 개최</td>
              </tr>
              <tr>
                <td>11</td>
                <td>World Golf Award 2018 한국 베스트 골프코스 수상</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2017</th>
              </tr>
              <tr>
                <td>04</td>
                <td>디아넥스 패밀리 스위트, 스위트 객실 신설</td>
              </tr>
              <tr>
                <td>04</td>
                <td>디아넥스 북카페 및 스포츠 홀 오픈</td>
              </tr>
              <tr>
                <td>10</td>
                <td>SK네트웍스-서울경제 레이디스 클래식 개최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2016</th>
              </tr>
              <tr>
                <td>11</td>
                <td>World Golf Award 2016 한국 베스트 골프코스 수상</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2015</th>
              </tr>
              <tr>
                <td>01</td>
                <td>디아넥스 준공</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2013</th>
              </tr>
              <tr>
                <td>05</td>
                <td>골프다이제스트 2013-2014 Korea Best course Silver부문 수상</td>
              </tr>
              <tr>
                <td>06</td>
                <td>SK telecom 2013 개최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2012</th>
              </tr>
              <tr>
                <td>05</td>
                <td>SK telecom 2012 개최</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2011</th>
              </tr>
              <tr>
                <td>05</td>
                <td>SK telecom 2011 개최</td>
              </tr>
              <tr>
                <td>05</td>
                <td>골프다이제스트 2011-2012 한국 베스트 코스 골프 부문 수상</td>
              </tr>
            </table>
          </div>
        </setcion>

        <setcion id="history-2021" :style="viewhistory4">
          <div class="ml-1">
            <table class="webTable table table-borderless">
              <tr>
                <th>2023</th>
                <td>05 SK Telecom OPEN 2023 KPGA 대회 개최</td>
              </tr>
              <tr>
                <th></th>
                <td>10 SK네트웍스x서울경제 레이디스 클래식 대회 개최</td>
              </tr>
              <tr>
                <th></th>
                <td>12 2023 서울 경제 한국 10대 골프장 선정(대상)</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="4">2022</th>
              </tr>
              <tr>
                <td>06 SK Telecom OPEN 2022 KPGA 대회 개최</td>
              </tr>
              <tr>
                <td>06 특색있는 MICE 장소로서 제주 Unique Venue 선정</td>
              </tr>
              <tr>
                <td>10 2022 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="4">2021</th>
              </tr>
              <tr>
                <td>06 SK Telecom OPEN 2021 KPGA 대회 개최</td>
              </tr>
              <tr>
                <td>10 2021 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>12 2021 서울 경제 2021 한국 10대 골프장 선정(대상)</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="3">2020</th>
              </tr>
              <tr>
                <td>10 2020 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>11 World Golf Award 2020 Korea’s Best Golf Course, Golf Hotel 선정</td>
              </tr>
            </table>
            <table class="webTable table table-borderless">
              <tr>
                <th rowspan="4">2019</th>
              </tr>
              <tr>
                <td>05 Golf Digest 2019~2020 Korea Best Course Bronze</td>
              </tr>
              <tr>
                <td>10 2019 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>11 Golf Magazine 2019 Korea’s Best Golf Course</td>
              </tr>
            </table>

            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="2">2023</th>
              </tr>
              <tr>
                <td>05</td>
                <td>SK Telecom OPEN 2023 KPGA 대회 개최</td>
              </tr>
              <tr>
                <td>10</td>
                <td>SK네트웍스x서울경제 레이디스 클래식 대회 개최</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2023 서울 경제 한국 10대 골프장 선정(대상)</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="4">2022</th>
              </tr>
              <tr>
                <td>06</td>
                <td>SK Telecom OPEN 2022 KPGA 대회 개최</td>
              </tr>
              <tr>
                <td>06</td>
                <td>특색있는 MICE 장소로서 제주 Unique Venue 선정</td>
              </tr>
              <tr>
                <td>10</td>
                <td>2022 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="4">2021</th>
              </tr>
              <tr>
                <td>06</td>
                <td>SK Telecom OPEN 2021 KPGA 대회 개최</td>
              </tr>
              <tr>
                <td>10</td>
                <td>2021 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2021 서울 경제 2021 한국 10대 골프장 선정(대상)</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="3">2020</th>
              </tr>
              <tr>
                <td>10</td>
                <td>2020 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>11</td>
                <td>World Golf Award 2020 Korea’s Best Golf Course, Golf Hotel 선정</td>
              </tr>
            </table>
            <table class="mobTable table table-borderless">
              <tr>
                <th colspan="4">2019</th>
              </tr>
              <tr>
                <td>05</td>
                <td>Golf Digest 2019~2020 Korea Best Course Bronze</td>
              </tr>
              <tr>
                <td>10</td>
                <td>2019 SK네트웍스-서울경제 레이디스 클래식 KLPGA 대회</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Golf Magazine 2019 Korea’s Best Golf Course</td>
              </tr>
            </table>
          </div>
        </setcion>
        <div class="mb-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'pinxhistory',
  methods: {
    firsthistoy() {
      if (this.isMobile) {
        this.checkstatus1 = 'active';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: 25%';
        this.thirdmovestatus = 'left: 50%';
        this.fourthmovestatus = 'left: 75%';
        this.viewhistory1 = 'display: block';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: none';
      } else {
        this.checkstatus1 = 'active';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: calc(100% - 360px)';
        this.thirdmovestatus = 'left: calc(100% - 240px)';
        this.fourthmovestatus = 'left: calc(100% - 120px)';
        this.viewhistory1 = 'display: block';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: none';
      }
    },
    secondhistory() {
      if (this.isMobile) {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'active';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: 25%';
        this.thirdmovestatus = 'left: 50%';
        this.fourthmovestatus = 'left: 75%';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: block';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: none';
      } else {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'active';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: 120px';
        this.thirdmovestatus = 'left: calc(100% - 240px)';
        this.fourthmovestatus = 'left: calc(100% - 120px)';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: block';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: none';
      }
    },
    thirdhistory() {
      if (this.isMobile) {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'active';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: 25%';
        this.thirdmovestatus = 'left: 50%';
        this.fourthmovestatus = 'left: 75%';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: block';
        this.viewhistory4 = 'display: none';
      } else {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'active';
        this.checkstatus4 = 'none';
        this.secondmovestatus = 'left: 120px';
        this.thirdmovestatus = 'left: 240px';
        this.fourthmovestatus = 'left: calc(100% - 120px)';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: block';
        this.viewhistory4 = 'display: none';
      }
    },
    fourthhistory() {
      if (this.isMobile) {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'active';
        this.secondmovestatus = 'left: 25%';
        this.thirdmovestatus = 'left: 50%';
        this.fourthmovestatus = 'left: 75%';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: block';
      } else {
        this.checkstatus1 = 'none';
        this.checkstatus2 = 'none';
        this.checkstatus3 = 'none';
        this.checkstatus4 = 'active';
        this.secondmovestatus = 'left: 120px';
        this.thirdmovestatus = 'left: 240px';
        this.fourthmovestatus = 'left: 360px';
        this.viewhistory1 = 'display: none';
        this.viewhistory2 = 'display: none';
        this.viewhistory3 = 'display: none';
        this.viewhistory4 = 'display: block';
      }
    },
    datareset_mobile() {
      this.checkstatus1 = 'active';
      this.checkstatus2 = 'none';
      this.checkstatus3 = 'none';
      this.checkstatus3 = 'none';
      this.secondmovestatus = 'left: 25%';
      this.thirdmovestatus = 'left: 50%';
      this.fourthmovestatus = 'left: 75%';
      this.viewhistory1 = 'display: block';
      this.viewhistory2 = 'display: none';
      this.viewhistory3 = 'display: none';
      this.viewhistory4 = 'display: none';
    },
    datareset_web() {
      this.checkstatus1 = 'active';
      this.checkstatus2 = 'none';
      this.checkstatus3 = 'none';
      this.checkstatus4 = 'none';
      this.secondmovestatus = 'left: calc(100% - 360px)';
      this.thirdmovestatus = 'left: calc(100% - 240px)';
      this.fourthmovestatus = 'left: calc(100% - 120px)';
      this.viewhistory1 = 'display: block';
      this.viewhistory2 = 'display: none';
      this.viewhistory3 = 'display: none';
      this.viewhistory4 = 'display: none';
    },
    onResize() {
      console.log('SLIDER-ON-RESIZE', window.innerWidth);
      this.isMobile = window.innerWidth <= 992;

      // this.height = window.innerWidth * 0.5;
    },
  },
  data() {
    return {
      checkstatus1: '',
      checkstatus2: '',
      checkstatus3: '',
      checkstatus4: '',
      secondmovestatus: '',
      thirdmovestatus: '',
      fourthmovestatus: '',
      viewhistory1: '',
      viewhistory2: '',
      viewhistory3: '',
      viewhistory4: '',
      isMobile: false,
      currentIndex: 0,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
      this.currentIndex = 1;
      if (this.isMobile) {
        this.datareset_mobile();
      } else {
        this.datareset_web();
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.timeline-nav .inner {
  position: relative;
  height: 51px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}
.timeline-nav .inner::before {
  content: ' ';
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.timeline-nav li {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  transition: left 0.4s;
}
.timeline-nav {
  width: 100%;
}
.timeline-nav a {
  display: block;
  width: 80px;
  color: #736357;
  font-size: 13px;
  text-align: center;
}
.timeline-nav a::after {
  content: ' ';
  display: block;
  margin: 13px auto;
  width: 27px;
  height: 27px;
  box-sizing: border-box;
  background-color: #fff;
  border: 5px solid #9b9b9b;
  border-radius: 50%;
  transition: border 0.4s;
}
.timeline-nav li.active a::after,
.timeline-nav a:hover::after {
  border-color: #c23e4d;
}
ol,
ul {
  list-style: none;
}

.mt-6 {
  margin-top: 4rem;
}
a {
  text-decoration: none;
}
th {
  font-size: 1.4rem;
}
@media (max-width: 992px) {
  th {
    padding: 0rem 1rem;
    width: 100%;
  }
  td {
    padding: 0.3rem 1rem;
    word-break: keep-all;
  }
  .webTable {
    display: none;
  }
  .mobTable {
    display: block;
  }
}
@media (min-width: 992px) {
  th {
    padding: 0rem 1rem;
    width: 100px;
  }
  td {
    padding: 0.3rem;
    word-break: keep-all;
  }
  .webTable {
    display: block;
  }
  .mobTable {
    display: none;
  }
}
</style>
